import React from "react"
import Helmet from 'react-helmet';
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Interests from "../components/interests";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { site, markdownRemark } = data // data.markdownRemark holds your post data
  const { siteMetadata } = site
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title} | {siteMetadata.title}</title>
        <meta name="description" content={frontmatter.metaDescription} />
      </Helmet>
      <div className="blog-post-container">
        <article className="post">
          <h1 className="centered h1">{frontmatter.title}</h1>
          {/* <h2 className="centered h2">{frontmatter.metaDescription}</h2> */}
          <h2 className="centered h2">{frontmatter.date}</h2>
          {!!frontmatter.thumbnail && (
            <div className="post-thumbnail" >
             <img src={frontmatter.thumbnail} alt={frontmatter.title} />
            </div>
          )}
          {(frontmatter.path === '/about') ? (
            <div
            className="blog-post-content centered" 
            dangerouslySetInnerHTML={{ __html: html }}
          />
          ) : (
            <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          )}
        </article>
      </div>
      {(frontmatter.path === '/about') && <Interests/>}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "YYYY")
        path
        title
        thumbnail
        metaDescription
      }
    }
  }
`