import React from "react"
//import {Link} from "gatsby"
import { FaEuroSign } from "@react-icons/all-files/fa/FaEuroSign";
import { FaRobot } from "@react-icons/all-files/fa/FaRobot";
import { IoMegaphoneOutline } from "@react-icons/all-files/io5/IoMegaphoneOutline";
import { FaPalette } from "@react-icons/all-files/fa/FaPalette";
import { GiBrickWall } from "@react-icons/all-files/gi/GiBrickWall";

export default function Interests() {
  
  const interestArray = [
    [<FaRobot />, "Artificial Intelligence", "Digitalizing industrial processes, reducing climate impact, improving efficiency, detecting anomalies"],
    [<IoMegaphoneOutline />, "Building in Public", "Oversharing every step, of each new project, to my Twitter followers"],
    [<GiBrickWall />, "Lego Technic", "Building what comes to my imagination. Robots. Vehicles. Remote control machines."], 
    [<FaEuroSign />, "Entrepreneurship", "Making internet money, solopreneurship, SaaS, startups"],
    [<FaPalette />, "Creative Coding", "Exploring procedurally generated art with Processing"],
    //[<BsTools />, "DIY / Crafting"],
  ];

  return (
    <div className="interests-array">
      {interestArray.map((interests, index) => (
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <div className="interest-icon">{interestArray[index][0]}</div>
              <div className="interest-text">{interestArray[index][1]}</div>
            </div>
            <div class="flip-card-back">
              <b><div>{interestArray[index][2]}</div></b>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}